@tailwind base;
@tailwind components;
@tailwind utilities;

img[alt~="roulette-static"] {
	width: 46px;
	height: 60px;
	transform: rotate(240deg);
	top: 50px;
	content: url("../static/ticker.png");
}
:root {
  --font-size-58: 58px;
  --font-size-48: 48px;
  --font-size-36: 36px;
  --font-size-32: 32px;
  --font-size-28: 28px;
  --font-size-27: 27px;
  --font-size-26: 26px;
  --font-size-25: 25px;
  --font-size-24: 24px;
  --font-size-22: 22px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-15: 15px;
  --font-size-14: 14px;
  --font-size-13: 13px;
  --font-size-12: 12px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-9: 9px;
  --font-size-8: 8px;
}
